<template>
  <div class="employeesLanding">
<!--    <img class="img" src="/img/logo.3e6f1bbb.png" alt="">-->
        <img class="img" :src="getlogo" alt="">

    <h2>专家在线</h2>
    <div class="srbox">
      <div class="srh" style="color: #9c9c9c; font-size: 14px;">
        <p style="padding-right: 30px">公司名称：{{$store.state.users.user.Company.CompanyName}}</p>
        <p>编号：{{$store.state.users.user.Company.CompanyId.slice(0,8).toUpperCase()}}</p>
      </div>
      <div class="srh">
        <p>账号：</p>
        <el-select class="sr" v-model="tel" filterable  placeholder="请选择">
          <el-option
              v-for="item in restaurants"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
<!--        <el-autocomplete-->
<!--            class="inline-input sr"-->
<!--            v-model="tel"-->
<!--            :fetch-suggestions="querySearch"-->
<!--            placeholder="请输入账号"-->
<!--            @select="handleSelect"-->
<!--        ></el-autocomplete>-->
      </div>
      <div class="srh">
        <p>密码：</p>
        <el-input type="password" v-model="input" class="sr" placeholder="请输入密码"></el-input>
      </div>
    </div>
    <el-button type="primary" @click="dl">
      <span v-if="isdl">注销</span>
      <span v-if="!isdl">登录</span>
    </el-button>
    <el-button type="primary" v-if="types == 3 || types == 2" @click="tg">跳过</el-button>
  </div>
</template>

<script>
import {Loading} from "element-ui";
import md5 from 'js-md5';
export default {
  name: "employeesLanding",
  data() {
    return {
      input: '',
      restaurants: [],
      state1: '',
      state2: '',
      userList: [],
      options: [
        {
          value: '医师',
          label: '医师'
        },
        {
          value: '医辅',
          label: '医辅'
        },
        {
          value: '护士',
          label: '护士'
        },
        {
          value: '加工质检',
          label: '加工质检'
        },{
          value: '导医客服',
          label: '导医客服'
        },
        {
          value: '采购',
          label: '采购'
        },
        {
          value: '仓库兼出纳',
          label: '仓库兼出纳'
        },
        {
          value: '市场拓展',
          label: '市场拓展'
        },
        {
          value: '综合行政',
          label: '综合行政'
        },
        {
          value: '其他',
          label: '其他'
        }
      ],
      value: '',
      tel: '',
      isdl: false,
      types: 0,
      names: '',
      pass: ''
    }
  },
  computed: {
    getlogo() {
      if (this.$store.state.users.user) {
        if (this.$store.state.users.user.Company.CompanyLogo) {
          return this._getUrl('shopLogo') + this.$store.state.users.user.Company.CompanyLogo
        }else {
          return this._getUrl('shopLogo') + 'VisumallLogo2-2.png'
        }
      }else {
        return this._getUrl('shopLogo') + 'VisumallLogo2-2.png'
      }
    }
  },
  created() {
    this.$store.commit('isShow/upBottom', false)
    // console.log(this.$route.query.type)
    this.$route.query.type?this.types = this.$route.query.type:this.types = 0
    if (this.$route.query.name) {
      this.names = this.$route.query.name
      this.pass = md5(this.$route.query.pass)
    }
    if (this.$store.state.users.employees) {
      this.tel = this.$store.state.users.employees.ExpertTel
      this.isdl = true
      this.$alert('已有登陆，请注销已登陆账号，再进行登陆！')
    }
  },
  mounted() {
    let loadingInstance = Loading.service({
      background: 'rgba(0, 0, 0, 0.7)',
      text: '加载中...'
    });
    this._api.userCurdApi.getEmployeesList()
        .then(res => {
          loadingInstance.close()
          if (res.GetListResult && res.GetListResult.length>0) {
            this.userList = res.GetListResult.map(item => {
              return {
                label: `${item.ExpertTel}(${item.ExpertName})`,
                value: `${item.ExpertTel}`
              }
            })
            this.restaurants = this.loadAll();
          }
        })
  },
  methods: {
    querySearch(queryString, cb) {
      let restaurants = this.restaurants;
      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    loadAll() {
      return this.userList
    },
    handleSelect(item) {
      this.tel = item.value.split('(')[0]
    },
    dl() {
      if (this.isdl) {
        this.$store.commit('users/upEmployees', null)
        this.isdl = false
        if (window.androids) {
          window.androids.logout()
        }
        return
      }

      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '登录中...'
      });
      this._api.userCurdApi.EmployeesLing(this.tel, this.input)
      .then(res => {
        // console.log(res)
        loadingInstance.close()
        if (res.ExpertLoginResult) {
          this.$store.commit('users/upEmployees', res.ExpertLoginResult)


          this.$message({
            message: '登陆成功！',
            type: 'success'
          });

          if (window.androids) {
            window.androids.getExpertData(res.ExpertLoginResult.ExpertId)
          }

          this._api.expertClinic.getExSignature()
          .then(res => {
            // console.log(res)
            if (res.GetResult) {
              this.$store.commit("expertClinic/upQmUrl", res.GetResult.UFPath)
            }
          })

          if (this.types == 1) {
            window.open(`http://user.visumall.com/webadmincommon/Login.aspx?ExpertId=${res.ExpertLoginResult.ExpertId}&UserName=${this.names}&Password=${this.pass}`)
          }else if (this.types == 2) {
            window.open(`http://user.visumall.com/webadmincommon/AutoLogin.aspx?ExpertId=${res.ExpertLoginResult.ExpertId}&UserName=${this.names}&Password=${this.pass}`)
          }else {
            if (this.$route.query.types == 1001) {
              this.$router.back(-1)
            }else {
              this.$router.push({path: '/'})
            }
          }
        }else {
          this.$alert(res.msg, '提示')
        }
      })
    },

    tg() {
      if (this.types == 2){
        window.open(`http://user.visumall.com/webadmincommon/AutoLogin.aspx?ExpertId=&UserName=${this.names}&Password=${this.pass}`)
      }else {
        this.$router.push({path: '/'})
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .employeesLanding {
    width: 100vw;
  }
  .img {
    display: block;
    width: 25vw;
    margin: 0 auto;
    margin-top: 10vh;
  }
  h2 {padding-top: .4rem}
  .srbox {
    padding-top: 0.4rem;
    .srh {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 0.2rem;
      .sr {
        width: 20vw;
      }
    }
  }
</style>
